import { useState } from 'react'

import Icon from '../Icon'

function HelpButton() {
  const [opened, setOpened] = useState(false)

  return (
    <>
      {!opened && (
        <div className="absolute bottom-0 -right-6 top-1/2">
          <button
            onClick={() => setOpened(true)}
            className="flex gap-2 p-1.5 rotate-90 items-center text-white text-sm bg-blue uppercase"
          >
            Ajuda
            <Icon
              name="Interrogation"
              width={17}
              height={17}
              className="-rotate-90"
            />
          </button>
        </div>
      )}

      {opened && (
        <div className="absolute max-h-[150px] bottom-0 right-0 top-1/2">
          <div className="flex justify-end">
            <button
              className="flex text-lightGray text-sm items-center gap-2 bg-white py-1.5 pl-5 pr-2.5 rounded-tl-xl"
              onClick={() => setOpened(false)}
            >
              Fechar
              <Icon name="CloseSmall" width={10} height={10} />
            </button>
          </div>

          <div className="shadow-2xl">
            <div className="bg-white max-w-[350px] border-b py-1.5 pl-2.5 pr-16">
              <button className="flex items-center">
                <a href="/">
                  <div className="flex">
                    <div>
                      <Icon name="Store" width={24} height={24} />
                    </div>

                    <div className="border-l mx-2.5 opacity-50" />

                    <div className="text-lightGray text-sm flex items-center">
                      Nossas lojas
                    </div>
                  </div>
                </a>
              </button>
            </div>

            <div className="bg-white max-w-[350px] border-b py-1.5 pl-2.5 pr-16">
              <button className="flex items-center">
                <a href="/">
                  <div className="flex">
                    <div>
                      <Icon name="Order" width={24} height={24} />
                    </div>

                    <div className="border-l mx-2.5 opacity-50" />

                    <div className="text-lightGray text-sm flex items-center">
                      Acompanhe seu pedido
                    </div>
                  </div>
                </a>
              </button>
            </div>

            <div className="bg-white max-w-[350px] border-b py-1.5 pl-2.5 pr-16">
              <button className="flex items-center">
                <a href="/">
                  <div className="flex">
                    <div>
                      <Icon name="Devolution" width={24} height={24} />
                    </div>

                    <div className="border-l mx-2.5 opacity-50" />

                    <div className="text-lightGray text-sm flex items-center">
                      Trocas e devoluções
                    </div>
                  </div>
                </a>
              </button>
            </div>

            <div className="bg-white max-w-[350px] border-b py-1.5 pl-2.5 pr-16">
              <button className="flex items-center">
                <a href="/">
                  <div className="flex">
                    <div>
                      <Icon name="Balloon" width={24} height={24} />
                    </div>

                    <div className="border-l mx-2.5 opacity-50" />

                    <div className="text-lightGray text-sm flex items-center">
                      Central de Atendimento
                    </div>
                  </div>
                </a>
              </button>
            </div>

            <div className="bg-white max-w-[350px] py-1.5 pl-2.5 pr-116 ">
              <button className="flex items-center">
                <a href="/">
                  <div className="flex">
                    <div>
                      <Icon name="BalloonHelp" width={24} height={24} />
                    </div>

                    <div className="border-l mx-2.5 opacity-50" />

                    <div className="text-lightGray text-sm flex items-center">
                      posso ajudar?
                    </div>
                  </div>
                </a>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default HelpButton
